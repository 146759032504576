var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-toolbar",
        { staticClass: "elevation-0", attrs: { color: "transparent" } },
        [
          _c("v-text-field", {
            attrs: {
              "append-icon": "search",
              label: "Search",
              "single-line": "",
              "hide-details": "",
            },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
          _c("v-spacer"),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "700px" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "mb-2",
                            attrs: { color: "primary", dark: "" },
                            on: { click: _vm.resetCurrentUser },
                          },
                          on
                        ),
                        [_vm._v("New User")]
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.showFormDialog,
                callback: function ($$v) {
                  _vm.showFormDialog = $$v
                },
                expression: "showFormDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "text-h5" }, [
                    _vm._v(_vm._s(_vm.formTitle)),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        [
                          _vm.isEditing &&
                          (_vm.isUserDisabled || !_vm.isUserEmailVerified)
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _vm.isUserDisabled
                                        ? _c(
                                            "v-alert",
                                            { attrs: { type: "warning" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.currentUser.displayName
                                                  ) +
                                                  " has been disabled. "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      !_vm.isUserEmailVerified
                                        ? _c(
                                            "v-alert",
                                            { attrs: { type: "warning" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.currentUser.displayName
                                                  ) +
                                                  " doesn't verified his email address. "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Email",
                                      disabled: _vm.isUserDisabled,
                                    },
                                    model: {
                                      value: _vm.currentUser.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.currentUser, "email", $$v)
                                      },
                                      expression: "currentUser.email",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Display Name",
                                      disabled: _vm.isUserDisabled,
                                    },
                                    model: {
                                      value: _vm.currentUser.displayName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.currentUser,
                                          "displayName",
                                          $$v
                                        )
                                      },
                                      expression: "currentUser.displayName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "append-icon": _vm.showPassword
                                        ? "visibility"
                                        : "visibility_off",
                                      type: _vm.showPassword
                                        ? "text"
                                        : "password",
                                      name: "password",
                                      label: "Password",
                                      hint: "At least 8 characters",
                                      counter: "",
                                    },
                                    on: {
                                      "click:append": function ($event) {
                                        _vm.showPassword = !_vm.showPassword
                                      },
                                    },
                                    model: {
                                      value: _vm.currentUser.password,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.currentUser,
                                          "password",
                                          $$v
                                        )
                                      },
                                      expression: "currentUser.password",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.accountsFormatted,
                                      label: "Accounts",
                                      multiple: "",
                                      chips: "",
                                      hint: "Select the accounts to add to the user",
                                      "persistent-hint": "",
                                      "item-text": "account_name",
                                      "item-value": "id",
                                    },
                                    model: {
                                      value: _vm.selectedAccounts,
                                      callback: function ($$v) {
                                        _vm.selectedAccounts = $$v
                                      },
                                      expression: "selectedAccounts",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.studioRoles,
                                      label: "Role",
                                      "single-line": "",
                                      hint: "Select the role to add to the user",
                                      "persistent-hint": "",
                                      "item-key": "roleName",
                                      "item-text": "roleName",
                                      "item-value": "roleCode",
                                    },
                                    model: {
                                      value: _vm.selectedStudioRoles,
                                      callback: function ($$v) {
                                        _vm.selectedStudioRoles = $$v
                                      },
                                      expression: "selectedStudioRoles",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: { click: _vm.closeFormDialog },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _vm.isEditing
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.updateUser },
                            },
                            [_vm._v("Update User")]
                          )
                        : _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.createUser },
                            },
                            [_vm._v("Create User")]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isLoading
            ? _c("v-progress-circular", {
                staticClass: "ml-2 mr-1",
                attrs: { indeterminate: "", size: "20", color: "primary" },
              })
            : _c(
                "v-icon",
                { attrs: { right: "" }, on: { click: _vm.listAllUsers } },
                [_vm._v("refresh")]
              ),
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.users,
          search: _vm.search,
          loading: _vm.isLoading,
          "sort-by": _vm.pagination.sortBy,
          "sort-desc": _vm.pagination.sortDesc,
          "item-key": "key",
          light: "",
        },
        on: {
          "update:sortBy": function ($event) {
            return _vm.$set(_vm.pagination, "sortBy", $event)
          },
          "update:sort-by": function ($event) {
            return _vm.$set(_vm.pagination, "sortBy", $event)
          },
          "update:sortDesc": function ($event) {
            return _vm.$set(_vm.pagination, "sortDesc", $event)
          },
          "update:sort-desc": function ($event) {
            return _vm.$set(_vm.pagination, "sortDesc", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item }) {
              return [
                _c("tr", { class: { "grey--text lighten-4": item.disabled } }, [
                  item.disabled
                    ? _c(
                        "td",
                        [
                          _vm._v(" " + _vm._s(item.email) + " "),
                          _c(
                            "v-chip",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                small: "",
                                disabled: "",
                                color: "grey lighten-2",
                              },
                            },
                            [_vm._v("Disabled")]
                          ),
                        ],
                        1
                      )
                    : _c("td", [_vm._v(_vm._s(item.email))]),
                  _c("td", [_vm._v(_vm._s(item.displayName))]),
                  _c("td", [_vm._v(_vm._s(item.emailVerified))]),
                  _c("td", [_vm._v(_vm._s(item.disabled))]),
                  _c("td", [_vm._v(_vm._s(item.nb_accounts))]),
                  _c("td", [_vm._v(_vm._s(item.studioRolesIndex))]),
                  _c(
                    "td",
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        {
                                          staticClass: "mr-2",
                                          attrs: { small: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editUser(item)
                                            },
                                          },
                                        },
                                        on
                                      ),
                                      [_vm._v("edit")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v("Edit user")])]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        {
                                          staticClass: "mr-2",
                                          attrs: { small: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.archiveUser(item)
                                            },
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.disabled
                                                ? _vm.mdiPackageUp
                                                : _vm.mdiPackageDown
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(item.disabled ? "Enable" : "Disable") +
                                " user"
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        {
                                          attrs: { small: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showDeleteDialog(item)
                                            },
                                          },
                                        },
                                        on
                                      ),
                                      [_vm._v("delete")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v("Delete user")])]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700" },
          model: {
            value: _vm.showConfirmationDialog,
            callback: function ($$v) {
              _vm.showConfirmationDialog = $$v
            },
            expression: "showConfirmationDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v("Warning"),
              ]),
              _c("v-card-text", [
                _c("p", [
                  _vm._v(" Are you sure you want to delete "),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(_vm._s(this.currentUser.displayName)),
                  ]),
                  _vm._v(" ? "),
                ]),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.showConfirmationDialog = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                  _c(
                    "v-btn",
                    { attrs: { text: "" }, on: { click: _vm.deleteUser } },
                    [_vm._v("Delete")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackbar.color, timeout: 3500 },
          model: {
            value: _vm.snackbar.isVisible,
            callback: function ($$v) {
              _vm.$set(_vm.snackbar, "isVisible", $$v)
            },
            expression: "snackbar.isVisible",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbar.text) + " "),
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function ($event) {
                  _vm.snackbar.isVisible = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }