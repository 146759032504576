
import { Component, Vue } from 'vue-property-decorator';
import { AnyObject, FirestoreAccount, Role, Snackbar, User } from '@/types';
import { DataTableHeader } from 'vuetify';
import { mapState } from 'vuex';
import { mapGetters } from 'vuex';
import merge from 'lodash.merge';
import { users } from '@/store/modules/easy-firestore/users';
import { SNACKBAR } from '@/constants/ui/snackbar';
import { ADMIN, MEMBER, SUPER_ADMIN, USER, VIEWER, WRITER } from '@/constants/user/roles';
import { mdiPackageDown, mdiPackageUp } from '@mdi/js';

// TODO: Split component

@Component({
	computed: {
		...mapState({ accounts: (state: any) => state.accounts.data }),
		...mapGetters(['periodFiltered', 'filters/whereConfFilter']),
	},
})
export default class UsersContent extends Vue {
	mdiPackageDown: string = mdiPackageDown;
	mdiPackageUp: string = mdiPackageUp;
	accounts: any;
	selectedAccounts: string[] = [];
	users: AnyObject[] = [];
	currentUser: AnyObject = {
		email: '',
		customClaims: {},
		displayName: '',
		emailVerified: false,
		disabled: false,
		creationTime: Date.now(),
		password: '',
	};
	selectedStudioRoles: number = 0;
	studioRoles: Role[] = [MEMBER, VIEWER, USER, WRITER, ADMIN, SUPER_ADMIN];
	showFormDialog: boolean = false;
	showConfirmationDialog: boolean = false;
	snackbar: Snackbar = { isVisible: false, timeout: SNACKBAR.TIMEOUT, text: '', color: 'complementary' };
	pagination = { sortBy: 'email', sortDesc: false }; // TODO: type
	showPassword: boolean = false;
	isEditing: boolean = false;
	search: string = '';
	isLoading: boolean = false;

	mounted() {
		this.listAllUsers();
	}

	resetCurrentUser() {
		this.isEditing = false;
		this.currentUser = {};
		this.selectedAccounts = [];
		this.selectedStudioRoles = 0;
	}

	listAllUsers() {
		this.isLoading = true;

		this.$httpsCallableFunction('listUsers', {})
			.then((res) => {
				const dataUsers = Object.values(res.data.users);

				let usersFormatted = res.data.users.map(function (user: User) {
					let nb_accounts = 0;
					try {
						nb_accounts = user.customClaims.accounts.length;
					} catch (error) {
						nb_accounts = 0;
					}
					let studioRolesIndex = 0;
					try {
						studioRolesIndex = user.customClaims.studioRoles;
					} catch (error) {
						studioRolesIndex = 0;
					}
					return {
						nb_accounts: nb_accounts,
						studioRolesIndex: studioRolesIndex,
					};
				});

				this.users = merge(dataUsers, usersFormatted);
				this.isLoading = false;
			})
			.catch(() => {
				this.isLoading = false;
			});
	}

	dispatchUser(action: 'set' | 'patch', data: AnyObject) {
		if (!data.id) throw new Error('Id must be provided to save user information');
		this.$store.dispatch(`${users.moduleName}/${action}`, data);
	}

	createUser() {
		const photoURL = 'https://raw.githubusercontent.com/mkfeuhrer/JarvisBot/master/images/JarvisBot.gif';
		this.closeFormDialog();
		this.isLoading = true;

		this.$httpsCallableFunction('createUser', {
			email: this.currentUser.email,
			displayName: this.currentUser.displayName,
			emailVerified: this.currentUser.emailVerified,
			photoURL,
			password: this.currentUser.password,
			disabled: this.currentUser.disabled,
			accounts: this.selectedAccounts,
			studioRoles: this.selectedStudioRoles,
		}).then((res) => {
			this.listAllUsers();
			this.showSnackbar('User has been created.', 'success');

			this.dispatchUser('set', {
				id: res.data.data.uid,
				disabled: false,
				deleted: false,
				displayName: this.currentUser.displayName,
				email: this.currentUser.email,
				photoURL,
			});
		});
	}

	editUser(user: User) {
		this.isEditing = true;
		this.currentUser = user;
		if (
			this.currentUser.customClaims == null ||
			typeof this.currentUser.customClaims.accounts === 'undefined' ||
			this.currentUser.customClaims.accounts === null
		) {
			this.selectedAccounts = [];
		} else {
			this.selectedAccounts = this.currentUser.customClaims.accounts;
		}
		if (
			this.currentUser.customClaims == null ||
			typeof this.currentUser.customClaims.studioRoles === 'undefined' ||
			this.currentUser.customClaims.studioRoles === null
		) {
			this.selectedStudioRoles = 0;
		} else {
			this.selectedStudioRoles = this.currentUser.customClaims.studioRoles;
		}
		this.showFormDialog = true;
	}

	updateUser() {
		this.isLoading = true;
		this.closeFormDialog();

		this.$httpsCallableFunction('updateUser', {
			accounts: this.selectedAccounts,
			email: this.currentUser.email,
			displayName: this.currentUser.displayName,
			photoURL: this.currentUser.photoURL,
			studioRoles: this.selectedStudioRoles,
		}).then(() => {
			this.isLoading = false;
			this.showSnackbar('User has been updated.', 'success');

			this.dispatchUser('patch', {
				id: this.currentUser.uid,
				displayName: this.currentUser.displayName,
				email: this.currentUser.email,
			});
		});
	}

	archiveUser(user: User) {
		this.isLoading = true;
		this.currentUser = user;

		this.$httpsCallableFunction('updateUser', {
			email: user.email,
			disabled: !user.disabled,
		}).then(() => {
			const index = this.users.indexOf(user);
			const disabledValue = !user.disabled;
			this.users[index].disabled = disabledValue;

			this.isLoading = false;
			this.showSnackbar(`User has been ${this.currentUser.disabled ? 'disabled' : 'enabled'}.`, 'success');

			this.dispatchUser('patch', {
				id: this.currentUser.uid,
				disabled: disabledValue,
			});
		});
	}

	showDeleteDialog(user: User) {
		this.currentUser = user;
		this.showConfirmationDialog = true;
	}

	deleteUser() {
		const index = this.users.indexOf(this.currentUser);

		this.isLoading = true;
		this.showConfirmationDialog = false;

		this.$httpsCallableFunction('deleteUser', { email: this.currentUser.email }).then(() => {
			this.users.splice(index, 1);
			this.isLoading = false;
			this.showSnackbar('User has been deleted', 'success');

			this.dispatchUser('patch', {
				id: this.currentUser.uid,
				disabled: true,
				deleted: true,
			});
		});
	}

	showSnackbar(text: string, color: string = this.snackbar.color!) {
		this.snackbar.isVisible = true;
		this.snackbar.text = text;
		this.snackbar.color = color;
	}

	closeFormDialog() {
		this.showFormDialog = false;
	}

	get headers(): DataTableHeader[] {
		return [
			{ text: 'Email', sortable: true, value: 'email' },
			{ text: 'Display Name', sortable: true, value: 'displayName' },
			{ text: 'Email Verified', value: 'emailVerified' },
			{ text: 'Disabled', value: 'disabled' },
			{ text: 'Nb Accounts', value: 'nb_accounts' },
			{ text: 'Roles', value: 'studioRolesIndex' },
			{ text: 'Actions', value: 'action', sortable: false },
		];
	}

	get isUserDisabled(): boolean {
		return this.currentUser.disabled;
	}

	get isUserEmailVerified(): boolean {
		return this.currentUser.emailVerified;
	}

	get formTitle(): string {
		return this.isEditing ? 'Edit User' : 'New User';
	}

	get accountsFormatted(): FirestoreAccount[] {
		return Object.values(this.accounts);
	}
}
